import { JSX, Show } from "solid-js";
import { ButtonRegular } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";
import { Spacer } from "~/widgets/spacer";
import { ModalInnerFrame } from "../modal";
import { DividerV2 } from "~/widgets/divider";

export type ConfirmationDialogProps = {
  title: string;
  message: JSX.Element;
  logoUrl?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  return (
    <ModalInnerFrame paddingClass="">
      <div class="bgGradient flex w-full flex-col items-center rounded-2xl font-inter  ">
        <Spacer height={24} />
        <Show when={props.logoUrl}>
          <div class="relative h-[64px] w-[64px] rounded-[50%]">
            <HubbleImage
              src={props.logoUrl!}
              class="h-full w-full rounded-full border border-neutral-200 object-cover "
              alt=""
            />
          </div>
          <Spacer height={16} />
        </Show>
        <span class="px-6 text-h3 ">{props.title}</span>
        <Spacer height={8} />
        <p class="text-base px-6">{props.message}</p>
        <Spacer height={24} />
        <DividerV2 class="bg-baseTertiaryMedium" />
        <Spacer height={16} />
        <div class="flex w-full flex-row justify-center gap-4 px-6 ">
          <ButtonRegular
            class="darkTheme:!bg-baseTertiaryDark darkTheme:!border-0 w-full !bg-baseTertiaryLight !text-basePrimaryDark"
            onClick={props.onCancel}
            isEnabled={true}
            isOutlined={true}
          >
            Cancel
          </ButtonRegular>
          <ButtonRegular
            class="w-full"
            onClick={props.onConfirm}
            isEnabled={true}
          >
            Confirm
          </ButtonRegular>
        </div>
        <Spacer height={16} />
      </div>
    </ModalInnerFrame>
  );
}
