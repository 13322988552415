import { CloseModalButton, ModalInnerFrame } from "../modal";
import {
  checkIconBlack,
  hubbleRewardsTextLg,
  hubbleRewardsTextSM,
  rewardsGif,
} from "~/assets/assets";

export function HubbleRewardsContent() {
  return (
    <ModalInnerFrame paddingClass="">
      <div class="relative flex w-full flex-col items-center rounded-2xl bg-gradient-to-b from-[#F8EFD7] to-[#FFFFFF]">
        <div class="flex h-fit flex-col items-end justify-between self-stretch">
          <div class="absolute right-2 top-2">
            <CloseModalButton />
          </div>
          <div class="mt-6 flex w-full flex-row items-start justify-start lg:w-full lg:justify-center ">
            <img
              src={hubbleRewardsTextLg}
              class="hidden md:block"
              alt="hubble rewards"
            />
            <img
              src={hubbleRewardsTextSM}
              class="ml-4 block md:hidden"
              alt="hubble rewards"
            />
          </div>

          <div class="mx-auto mt-6 px-3">
            <img
              src={rewardsGif}
              class="rounded-2xl border border-baseTertiaryDark md:h-72"
              alt="hubble rewards"
            />
          </div>

          <div class="p-3 lg:p-6">
            <p class="text-bold text-textDark lg:text-h5">
              Earn & toggle 'ON' for instant discount on brand card
            </p>

            <hr class="my-3 h-px w-full border-0 bg-gray-200" />

            <div class="flex items-start gap-1">
              <img src={checkIconBlack} alt="" />
              <div>
                <p class="text-mediumBold text-successDark md:text-bold">
                  Using rewards for instant discounts won't impact your earning
                  rate.{" "}
                  <span class="text-mediumBold text-textDark md:text-normal">
                    You earn rewards with every purchase!
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalInnerFrame>
  );
}
